@tailwind base;
@tailwind components;
@tailwind utilities;

.yarl__container {
    background-color: rgba(0, 0, 0, 0.8) !important;
    backdrop-filter: blur(5px)
}


@layer components {
    .click {
        @apply hover:cursor-pointer transform transition-transform active:scale-105 duration-100 ease-in-out;
    }
}
